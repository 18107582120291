import React, { Component } from "react";
import { Row, Spin, Empty } from 'antd';
import '../../css/Product.css';

import AliceCarousel from 'react-alice-carousel';
import ProductCart from "./ProductCard"
import axios from 'axios';
import Cookies from 'universal-cookie';
import swal from 'sweetalert';
import { config } from '../../config/config';

const cookies = new Cookies();

var ip = config.ipServer;

export default class ProductSlide extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: "",
            user: [],
            product: [],
            statusDataProduct: false
        };

        this.list_productcade = this.list_productcade.bind(this);
        // this.list_productcade2 = this.list_productcade2.bind(this);
        // this.list_productcade3 = this.list_productcade3.bind(this);
        // this.list_productcade4 = this.list_productcade4.bind(this);
        // this.list_productcade5 = this.list_productcade5.bind(this);
        this.list_productcade6 = this.list_productcade6.bind(this);
        this.list_productcade7 = this.list_productcade7.bind(this);
    }

    componentWillMount() {
        this.setState({
            token: cookies.get('token_key', { path: '/' }),
            user: cookies.get('user', { path: '/' })
        });
    }

    async componentDidMount() {
        // var url_product = ip + "/Product/find/id/" + this.props.catId;
        // const product = await (await axios.get(url_product)).data;
        // this.setState({
        //     product: product
        // });

        var url_product = "";
        if (this.state.token === "" || this.state.token === null || this.state.token === undefined ||
            this.state.user.levelId === "" || this.state.user.levelId === null || this.state.user.levelId === undefined) {
            url_product = ip + "/Product/find/notauthorization/id/" + this.props.catId + "/16/";

        } else {
            url_product = ip + "/Product/find/authorization/id/" + this.props.catId;
        }

        const product = await (await axios.get(url_product, { headers: { "token": this.state.token, "key": this.state.user?.username } })).data;
        if ((product.statusCode === 500) || (product.statusCode === 400)) {
            swal("Error!", "เกิดข้อผิดพลาดในการเข้าสู่ระบบ \n กรุณาเข้าสู่ระบบใหม่", "error").then((value) => {
                this.setState({
                    token: cookies.remove('token_key', { path: '/' }),
                    user: cookies.remove('user', { path: '/' })
                });
                window.location.replace('/Login', false);
            });
        } else {
            this.setState({
                product: product,
                statusDataProduct: true
            });
        }
    }

    list_productcade() {
        return this.state.product.map((product) => {
            return <ProductCart product={product} />
        });
    }

    list_productcade2() {
        return this.state.product.map((product) => {
            return <div style={{ marginRight: "2px" }}><ProductCart product={product} /></div>
        });
    }

    list_productcade3() {
        return this.state.product.map((product) => {
            return <div style={{ marginRight: "10px" }}><ProductCart product={product} /></div>
        });
    }

    list_productcade4() {
        return this.state.product.map((product) => {
            return <div style={{ marginRight: "5px" }}><ProductCart product={product} /></div>
        });
    }

    list_productcade5() {
        return this.state.product.map((product) => {
            return <div style={{ marginRight: "5px" }}><ProductCart product={product} /></div>
        });
    }

    list_productcade6() {
        return this.state.product.map((product) => {
            return <div style={{ marginRight: "20px" }}><ProductCart product={product} /></div>
        });
    }

    list_productcade7() {
        return this.state.product.map((product) => {
            return <div style={{ marginRight: "10px" }}><ProductCart product={product} /></div>
        });
    }

    list_productcade8() {
        return this.state.product.map((product) => {
            return <div style={{ marginRight: "80px" }}><ProductCart product={product} /></div>
        });
    }

    onSlideChange(e) {

    }

    onSlideChanged(e) {

    }

    render() {
        const responsive = {
            0: {
                items: 1
            },
            250: {
                items: 2
            },
            400: {
                items: 3
            },
            1020: {
                items: 4
            },
            1300: {
                items: 5
            },
            1900: {
                items: 5
            },
            2500: {
                items: 7
            }
        };

        return (
            <>
                {
                    (this.state.statusDataProduct) ?
                        (this.state.product.length > 0) ?
                            ((window.innerWidth < 250) && (this.state.product.length < 2)) ?
                                <Row id="Row-Product5">
                                    {this.list_productcade1()}
                                </Row>
                                :
                                ((window.innerWidth < 400) && (this.state.product.length < 3)) ?
                                    <Row id="Row-Product5">
                                        {this.list_productcade2()}
                                    </Row>
                                    :
                                    ((window.innerWidth < 450) && (this.state.product.length < 3)) ?
                                        <Row id="Row-Product5">
                                            {this.list_productcade3()}
                                        </Row>
                                        :
                                        ((window.innerWidth < 1020) && (this.state.product.length < 4)) ?
                                            <Row id="Row-Product5">
                                                {this.list_productcade4()}
                                            </Row>
                                            :
                                            ((window.innerWidth < 1300) && (this.state.product.length < 5)) ?
                                                <Row id="Row-Product5">
                                                    {this.list_productcade5()}
                                                </Row>
                                                :
                                                ((window.innerWidth < 1900) && (this.state.product.length < 6)) ?
                                                    <Row id="Row-Product5">
                                                        {this.list_productcade6()}
                                                    </Row>
                                                    :
                                                    ((window.innerWidth < 2500) && (this.state.product.length < 7)) ?
                                                        <Row id="Row-Product5">
                                                            {this.list_productcade7()}
                                                        </Row>
                                                        :
                                                        ((window.innerWidth > 2500) && (this.state.product.length < 7)) ?
                                                            <Row id="Row-Product5">
                                                                {this.list_productcade8()}
                                                            </Row>
                                                            :
                                                            <Row id="Row-Product5">
                                                                <AliceCarousel
                                                                    duration={400}
                                                                    autoPlay={true}
                                                                    responsive={responsive}
                                                                    startIndex={1}
                                                                    fadeOutAnimation={true}
                                                                    mouseDragEnabled={true}
                                                                    playButtonEnabled={true}
                                                                    autoPlayInterval={2000}
                                                                    autoPlayDirection="ltr"
                                                                    autoPlayActionDisabled={true}
                                                                    onSlideChange={this.onSlideChange}
                                                                    onSlideChanged={this.onSlideChanged}
                                                                >
                                                                    {this.list_productcade()}
                                                                </AliceCarousel>
                                                            </Row>
                            :
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                        :
                        <Row id="row-spin-slide">
                            <Spin size="large" />
                        </Row>
                }
            </>
        )

    }
}