import React, { Component } from "react";
import { Container, Image } from 'react-bootstrap';
import { Row, Col } from 'antd';
import '../css/About.css';
import logo from '../img/hits_logo2.png'
export default class Abount extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return (
            <Container fluid>
                <Row id="About">
                    <Col xs={24} md={24} xl={24}>
                        <Row>
                            <Col xs={2} md={8} xl={8}></Col>
                            <Col xs={20} md={8} xl={8}>
                                <Image
                                    src={logo}
                                    style={{ paddingBottom: "0.3%", paddingTop: "0.3%" }}
                                    fluid
                                ></Image>
                            </Col>
                            <Col xs={2} md={8} xl={8}></Col>
                        </Row>
                        <Row >
                            <Col id="col-about">
                                <p>
                                    hits ผู้ผลิตและผู้จัดจำหน่ายสินค้านำเข้าจากต่างประเทศที่ได้คุณภาพ มาตรฐานระดับสากล ด้วยประสบการณ์ที่มากกว่า 30 ปี ในวงการวัสดุอุปกรณ์ เครื่องมือช่างตกแต่งบ้าน และเฟอร์นิเจอร์ เราคัดสรรสินค้าที่มีคุณภาพเป็นมาตรฐานออกสู่ตลาด รับประกันคุณภาพและบริการ ได้ผลตอบรับที่ดีและความไว้วางใจจากช่างมืออาชีพ วิศวกร สถาปนิก โครงการชั้นนำ โรงงาน และร้านค้าตัวแทนจำหน่าย จากนี้และในอนาคตเรามุ่งมั่นพัฒนาสินค้าและบริการที่ดีสู่คุณ
                            </p>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        )

    }
}